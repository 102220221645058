<script lang="ts">
import MarkdownIt from 'markdown-it';
import abbreviation from 'markdown-it-abbr';
import deflist from 'markdown-it-deflist';
import footnote from 'markdown-it-footnote';
import mark from 'markdown-it-mark';

/*
 * adminのPreviewと見せ方を合わせたいので、設定する項目も合わせる
 * REF: https://github.com/400f/okanekenkoadmin/blob/755c4cc6baa0c859b98bfb27a96324347675cf7f/client/src/components/atoms/AppMarkdown.vue
 */

const md = MarkdownIt().use(footnote).use(deflist).use(abbreviation).use(mark);

md.set({
  html: true,
  xhtmlOut: true,
  breaks: true,
  linkify: true,
  typographer: true,
  langPrefix: 'language-',
  quotes: '“”‘’',
});

md.renderer.rules.table_open = () => `<table class="AppMarkdown_table">\n`;

// NOTE: https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md
type RenderRule = Exclude<typeof md.renderer.rules.link_open, undefined>;
const defaultLinkRenderer: RenderRule =
  md.renderer.rules.link_open ||
  ((tokens, idx, options, _, self) => self.renderToken(tokens, idx, options));

const anchorAttributes = {
  class: 'AppMarkdown_anchor',
  target: '_blank',
  rel: 'noopener noreferrer nofollow',
};

md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  Object.keys(anchorAttributes).forEach((attribute) => {
    const attrKey = attribute as keyof typeof anchorAttributes;
    const attrIndex = tokens[idx].attrIndex(attrKey);

    const value = anchorAttributes[attrKey];
    const token = tokens[idx];

    if (attrIndex < 0) {
      token.attrPush([attribute, value]); // add new attribute
      return;
    }

    if (token.attrs) {
      token.attrs[attrIndex][1] = value;
    }
  });
  return defaultLinkRenderer(tokens, idx, options, env, self);
};
</script>

<script lang="ts" setup>
const props = defineProps<{ body: string }>();
const html = computed(() => md.render(props.body));
</script>

<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div :class="$style.markdown" v-html="html" />
</template>

<style lang="scss" module>
.markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;

    &:nth-child(n + 2) {
      margin-top: 1.75em;
    }
  }

  p {
    &:nth-child(n + 2) {
      margin-top: 1.75em;
    }
  }

  ol {
    padding-left: 2em;

    li {
      list-style: decimal;
    }

    ul,
    ol {
      margin-top: 0.75em;
    }

    &:nth-child(n + 2) {
      margin-top: 2em;
    }
  }

  ul {
    padding-left: 1.5em;

    li {
      list-style: disc;

      &:nth-child(n + 2) {
        margin-top: 0.5em;
      }

      li {
        list-style: circle;
      }
    }

    ul,
    ol {
      margin-top: 0.75em;
    }

    &:nth-child(n + 2) {
      margin-top: 2em;
    }

    ul:nth-child(n + 2) {
      margin-top: 0.5em;
    }
  }

  hr {
    &:nth-child(n + 2) {
      margin-top: 1.75em;
    }
  }

  img {
    max-width: 100%;
  }

  table {
    &:nth-child(n + 2) {
      margin-top: 1.75em;
    }

    word-break: break-all;
    border-spacing: 0;
    border-collapse: collapse;
    display: block;

    th,
    td {
      padding: 6px 13px;
      border: 1px solid $color-border-high-emphasis;
    }

    tr {
      border: 1px solid $color-border-high-emphasis;
    }
  }
}
</style>
